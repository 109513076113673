/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, useState, useCallback } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { GlobalContext } from "../context/GlobalContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { apiService } from "../service/api.service";
import Swal from "sweetalert2";
import { Footer } from "../common/Footer";
import TagManager from 'react-gtm-module'; // Import the TagManager
import LpShortcuts from '../common/LpShortcuts';




 







export const LandingPageShort = () => {

    const tagManagerArgs = {
        gtmId: "GTM-MD3PPCXX",
      };
    
      TagManager.initialize(tagManagerArgs);
      useEffect(() => {
        window.dataLayer.push({
          event: "LpGetHelpPageView",
          title: "LandingPage.js",
        });
      }, []);













    const navigate = useNavigate();
    const messagesEndRef = useRef(null);
    const globalContext = useContext(GlobalContext);

    const [queryParams] = useSearchParams();
    const [query, setQuery] = useState("");
    const [messages, setMessages] = useState([]);
    const [showLogin, setShowLogin] = useState(false);
    const [showForgot, setShowForgot] = useState(false);
    const [passType, setPassType] = useState("password");
    const [goToCheckout, setGoToCheckout] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const TypingIndicator = () => {
        return (
          <div className="typing-indicator">
            <span>Lauren is responding</span>
            <span className="dots">...</span>
          </div>
        );
      };
    const { register, handleSubmit, formState: { errors }, setError } = useForm();

    const buyFreeTrial = useCallback(() => {
        // navigate(`/checkout?trial=${selectedPlan}`);
        // INTEGRATED FOR STRIPE
        console.log("selected plan: ", selectedPlan);
        navigate(`/stripe-checkout`, { replace: true });
    }, [navigate, selectedPlan]);


 




    useEffect(() => {
        if (queryParams.get('action') === 'login') {
            setShowLogin(true);
        }
    }, [queryParams]);

 

    const getPlanList = () => {
        apiService.getPlans().then((res) => {
            if (res.status === 200) {
                setSelectedPlan(res.data.data[0].id);
            }
        }).catch((err) => {
            console.log("plan list error: ", err);
        });
    };

 

    const onSubmitLogin = (data) => {
        globalContext.setLoader(true);
        apiService.login(data).then((res) => {
            globalContext.setLoader(false);
            if (res.status === 200) {
                localStorage.setItem("user-info", JSON.stringify(res.data));
                globalContext.setLoggedIn(true);
                globalContext.setUserInfo(res.data.data);
                globalContext.setSessionComplete(false);
                setShowLogin(false);
                navigate("/?chatType=text", { replace: true });
            }
        }).catch((err) => {
            console.log("login error: ", err);
            globalContext.setLoader(false);
            setError('password', { type: 'custom', message: err.response.data.detail });
        });
    };

 

    const handleSubscribeClick = (e) => {
        e.preventDefault();
        alert("Subscribe link clicked!");
        // We'll uncomment this once we confirm the alert is working
        // buyFreeTrial();
    };

    return (
        <>
            <div className="main-landing-page">
                <header>
                    <div className="header-logo">
                        <img src={require("../assets/images/logo-main.svg").default} alt="logo_img" />
                    </div>
                </header>
<div className="shortcuts-landing-page-container">

<section className="shortcuts-hero-section">
                   
                   <Row className="align-items-center">
                       <Col md={12}>
                           <div className="shortcut-hero-content">
                           Access Your Favorite Sites Faster! Get All Of Sites and Logins In One Place! First, Select Your Shortcuts...                                                      </div>
                       </Col>
                   </Row>
             
               
           </section>

           <div className="shortcut-container">
               <div className="th-prominent-shortcut dqt-shortcut">
                   <div className="th-prominent-shortcut-window">
                      
                

                   <LpShortcuts />





                   </div>
               </div>
           </div>

           <section className="shortcuts-how-it-work">
               <div className="how-it-works-inner">
                   <h2>How It Works </h2>
                   <div className="how-it-works-inner-content">
                       <div className="how-it-works-bullet-numbers">1</div>
                       <div className="work-content-right">
                           <h6>Chat</h6>
                           <p>Pick your favorite shortcuts to socials, emails, websites, and more!</p>
                       </div>
                   </div>
                   <div className="how-it-works-inner-content">
                       <div className="how-it-works-bullet-numbers">2</div>
                       <div className="work-content-right">
                           <h6>Create Account</h6>
                           <p>Create your Helpr account to access shortcuts and unlimited AI chat with a free 7 day trial, completely risk free</p>
                       </div>
                   </div>
                   <div className="how-it-works-inner-content">
                       <div className="how-it-works-bullet-numbers">3</div>
                       <div className="work-content-right">
                           <h6>Get Unlimited Chat Help Online</h6>
                           <p>With the help of your AI Tech Expert, you can get almost any question answered instantly. 24/7 help and unlimited questions, so you never have to go it alone online</p>
                       </div>
                   </div>
               </div>
           </section>

           <section className="pricing-area-outer">
               <div className="pricing-area-inner">
                   <h2>Free 7 Day Trial, Then Only $20/month. No commitments.</h2>
                   <p>Unlock unlimited Helpr chat with your AI tech expert for just $20 per month, cancel anytime.</p>
                   <Button variant="unset" onClick={() => buyFreeTrial()}>Get Started <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.2203 10.9062L11.3313 1.14895L1.57769 1.43685M10.2334 2.26696L0.821276 11.8513" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /></svg></Button>
               </div>
           </section>


</div>
               

            </div>
            <Footer />
        </>
    );
};