import React, { useContext, useEffect, useState } from "react";
import { paddleClientToken } from "../config/config";
import { useNavigate, useSearchParams } from "react-router-dom";
import { apiService } from "../service/api.service";
import { GlobalContext } from "../context/GlobalContext";
import TagManager from 'react-gtm-module'; // Import the TagManager
import Swal from "sweetalert2";







const Payment = () => {
  const tagManagerArgs = {
    gtmId: "GTM-MD3PPCXX",
  };

  TagManager.initialize(tagManagerArgs);
  useEffect(() => {
    window.dataLayer.push({
      event: "CheckoutPageView",
      title: "Paddle.js",
    });
  }, []);






  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const globalContext = useContext(GlobalContext);
  const [transaction, setTransaction] = useState(null);
  const [checkoutVisible, setCheckoutVisible] = useState(true);

  useEffect(() => {
    // Setup Sandbox
    // window.Paddle.Environment.set("production");
    window.Paddle.Environment.set("sandbox");
    /*  --------  comment out when switching to live*/
    // Initialize Paddle
    window.Paddle.Initialize({
      token: paddleClientToken,
      eventCallback: function (data) {
        console.log("Callback data", data);
      },
    });
  }, []);

  useEffect(() => {
    console.log("transaction: ", transaction);
    console.log("checkoutVisible: ", checkoutVisible);
    
    if (queryParams.get("transaction") || queryParams.get("trial-transaction")) {

      let transaction = null
      if (queryParams.get("transaction")) {
        transaction = queryParams.get("transaction");
      } else if (queryParams.get("trial-transaction")) {
        transaction = queryParams.get("trial-transaction");
      }

      // Open Checkout
      window.Paddle.Checkout.open({
        settings: {
          allowDiscountRemoval: true,
          allowLogout: false,
          displayMode: "inline",
          locale: "en",
          showAddDiscounts: true,
          showAddTaxId: true,
          frameTarget: "checkout-frame",
          frameStyle:
            "width: 500px; background: rgb(101 163 255 / 6%); border-right: 1px solid rgba(0, 0, 0, 0.07); height: 100vh; padding-top: 80px;",
          theme: "light",
          allowedPaymentMethods: ["card"],
          successUrl: `${queryParams.get("transaction") ? `${window.location.origin}/?chatType=text` : `${window.location.origin}/set-password/${transaction}`}`,
        },
        transactionId: transaction,
      });
      setTimeout(() => getTransaction(transaction), 500);

    }

    if (queryParams.get("plan")) {
      createTransaction();
    }
    if (queryParams.get("trial")) {
      createTrialTransaction();
    }

    return () => {
      if (window.Paddle && window.Paddle.Checkout) {
        window.Paddle.Checkout.close();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const frame = document.querySelector('.checkout-frame');
      if (frame) {
        setCheckoutVisible(frame.offsetWidth !== 0 && frame.offsetHeight !== 0);
      }
    }, 100);

    return () => clearInterval(intervalId);

  }, []);

  const createTransaction = () => {
    globalContext.setLoader(true);
    apiService
      .createTransaction({ plan_id: queryParams.get("plan") })
      .then((res) => {
        globalContext.setLoader(false);
        if (res.status === 200) {
          navigate(`/checkout?transaction=${res.data.data.id}`, {
            replace: true,
          });
        }
      })
      .catch((err) => {
        console.log("err: ", err);
        globalContext.setLoader(false);
      });
  };

  const createTrialTransaction = () => {
    globalContext.setLoader(true);
    apiService
      .createTrialTransaction({ plan_id: queryParams.get("trial") })
      .then((res) => {
        globalContext.setLoader(false);
        if (res.status === 200) {
          navigate(`/checkout?trial-transaction=${res.data.data.id}`, {
            replace: true,
          });
        }
      })
      .catch((err) => {
        console.log("err: ", err);
        globalContext.setLoader(false);
      });
  };

  const getTransaction = (transaction) => {
    apiService
      .getTransaction(transaction)
      .then((res) => {
        if (res.status === 200) {
          if ((res.data?.data?.details?.totals.tax === '0') && (res.data?.data?.details?.totals.grand_total !== '0')) {
            getTransaction(transaction);
          } else {
            setTransaction(res.data.data);
          }
        }
      })
      .catch((err) => {
        console.log("err: ", err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong! Please try again.",
        }).then(() => {
          window.location.reload();
        })
      });
  };

  return (
    <>
      <div className="checkout-outer">
        <div className="checkout-frame">
        <div className="checkout-bullet-points-headline">
     <b>Get Unlimited Help From Your
    On Demand AI Tech Expert!</b>
</div>
        <div className="checkout-pricing-headline">
     <b>Unlimited questions, 7 days free, then just $20/month. Cancel anytime.</b>
</div>



        </div>
        <div className="price-total">


     {/* chat section */}
     <div className="chat-container-checkout">

                <div className="th-prominent-chat dqt-chat">
                    <div className="th-prominent-chat-window">
                        <div className="chat-header-main">
                            <div className="expert-profile expert-profile--blue">
                                <div className="avatar"><div className="avatar-wrapper">
                                    <img src={require("../assets/images/helprpfp.png")} alt="img" />
                                </div>
                                </div>
                                <div className="details">
                                    <p className="name">Lauren </p>
                                    <p className="title">AI Tech Expert</p>
                                    {/* <p className="credentials">10 years of experience helping hundreds of people</p> */}
                                    {/* <div className="rating">

                                        <p className="question-count">
                                            <span className="me-2">
                                                <i className="fa-solid fa-star me-1"></i>
                                                <i className="fa-solid fa-star me-1"></i>
                                                <i className="fa-solid fa-star me-1"></i>
                                                <i className="fa-solid fa-star me-1"></i>
                                                <i className="fa-solid fa-star"></i>
                                            </span>
                                            <strong className="count-number">8,535</strong> Satisfied customers</p>
                                    </div> */}
                                </div>
                            </div>
                           
                        </div>
                        <div data-testid="dialog-checkout" className="dialog-checkout body">
                            <div className="content">
                                <div className="conversation">
                                         
                                        
                                            <div className="expert message th-chat-message">
                                                <img className="right-message-img" src={require("../assets/images/helprpfp.png")} alt="img" />
                                                <div className="content">
                                                    <span className="name">Lauren, AI Tech Expert</span>
                                                    <div className="checkout-chat-message">
                                                    Your AI tech expert is ready to help at no cost today! Just fill out 
the form to claim your 7 day free trial 
and get your issue resolved fast and ask 
unlimited questions!!
                                                    </div>



                                                    <div className="checkout-chat-message-900">
                                                    Your expert is ready to help, just fill out 
the form to claim your 7 day free trial 
and get your issue resolved fast! Get Unlimited 24/7 Help From Your On Demand AI Tech Expert. Never Deal With Technology Alone Again!
                                                    </div>



                                                </div>
                                            </div>
                                          
                                      
                      
                                </div>
                            </div>
                            <div className="checkout-bullet-points-container">
{/* <div className="checkout-bullet-points-headline">
     <b>Get Unlimited Help From Your
    On Demand AI Tech Expert!</b>
</div> */}
<div className="checkout-pricing-headline-900">
     <b>Unlimited questions, completely free for 7 days, then just $20/month. Cancel anytime.</b>
</div>
                                <div className="checkout-bullet-points">
                                    <div className="checkout-bullet-point">
                                        
                                        <p>Never deal with technology alone again! Your 
                                        AI tech expert is always online and ready to assist!</p>
                                    </div>
                                    <div className="checkout-bullet-point">
                                       
                                        <p>Ask unlimited questions about any topic 24/7, 
  get an instant response from your expert!
</p>
                                    </div>
                                    <div className="checkout-bullet-point">
                                        
                                        <p>Instant help with any topic, from internet issues, 
  to recipes, math problems, balancing budgets, 
  anything you can think of!</p>
                                    </div>


                                    {/* <div className="checkout-bullet-point-1400">
                                        
                                        <p>Never deal with technology alone again! Your 
                                        AI tech expert is ready to assist with any topic or question, 24/7! </p>
                                    </div> */}




                                    </div>
                                    </div>
                        </div>
                    




 {/* {checkoutVisible && */}

{/* <div className="price-total wide">
            <div className="pricing-total-inner">
              <h5 onClick={() => navigate(-1)}>
                <i className="fa-solid fa-arrow-left"></i>Back
              </h5>
              <div className="pricing-total-area">
                {transaction ? (
                  <>
                    <div className="price-total-inner">
                      <p>Sub Total:</p>
                      <h6> ${transaction?.details?.totals.subtotal / 100}</h6>
                    </div>
                    <div className="price-total-inner">
                      <p>Tax:</p>
                      <h6>${transaction?.details?.totals.tax / 100}</h6>
                    </div>
                    {transaction?.details?.totals.discount != 0 && (
                      <div className="price-total-inner">
                        <p>Discount:</p>
                        <h6> ${transaction?.details?.totals.discount / 100}</h6>
                      </div>
                    )}
                    <div className="grand-total">
                      <p>Grand Total: </p>
                      <h6>${transaction?.details?.totals.grand_total / 100}</h6>
                    </div>
                  </>
                ): 
                <div className="pricing-loader-outer">
                  <span className="pricing-loader"></span>
                </div>}
              </div>
            </div>

</div> */}


          {/* } */}







                    </div>
                </div>
            </div>


            {/* {checkoutVisible && */}



{/* <div className="price-total narrow">
            <div className="pricing-total-inner">
              <h5 onClick={() => navigate(-1)}>
                <i className="fa-solid fa-arrow-left"></i>Back
              </h5>
              <div className="pricing-total-area">
                {transaction ? (
                  <>
                    <div className="price-total-inner">
                      <p>Sub Total:</p>
                      <h6> ${transaction?.details?.totals.subtotal / 100}</h6>
                    </div>
                    <div className="price-total-inner">
                      <p>Tax:</p>
                      <h6>${transaction?.details?.totals.tax / 100}</h6>
                    </div>
                    {transaction?.details?.totals.discount != 0 && (
                      <div className="price-total-inner">
                        <p>Discount:</p>
                        <h6> ${transaction?.details?.totals.discount / 100}</h6>
                      </div>
                    )}
                    <div className="grand-total">
                      <p>Grand Total: </p>
                      <h6>${transaction?.details?.totals.grand_total / 100}</h6>
                    </div>
                  </>
                ): 
                <div className="pricing-loader-outer">
                  <span className="pricing-loader"></span>
                </div>}
              </div>
            </div>

</div> */}



          {/* } */}
        </div>
      </div>
    </>
  );
};

export default Payment;
