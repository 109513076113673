/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { stripePublishKey } from "../config/config";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GlobalContext } from "../context/GlobalContext";
import { apiService } from "../service/api.service";
import { Col, Row } from "react-bootstrap";

export const StripeCheckout = () => {

  const stripePromise = loadStripe(stripePublishKey);

  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const globalContext = useContext(GlobalContext);
  const [clientSecretKey, setClientSecretKey] = useState("");

  const options = {
    clientSecret: clientSecretKey,
  };

  useEffect(() => {
      if (queryParams.get("plan")) {
        createCheckout();
    }
    if (queryParams.get("client_secret")) {
        setClientSecretKey(queryParams.get("client_secret"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);


  const createCheckout = () => {
    globalContext.setLoader(true);
    apiService
      .createStripeCheckout({ plan_id: queryParams.get("plan") })
      .then((res) => {
        globalContext.setLoader(false);
        if (res.status === 200) {
          navigate(`/stripe-checkout?client_secret=${res.data.data.client_secret}`, {
            replace: true,
          });
        }
      })
      .catch((err) => {
        console.log("err: ", err);
        globalContext.setLoader(false);
      });
  };

  return (
    <>
      <div className="checkout-bullet-points-headline">
        <b>Get Unlimited Help From Your On Demand AI Tech Expert!</b>
      </div>
      <div className="checkout-pricing-headline">
        <b>
          Unlimited questions, 7 days free, then just $20/month. Cancel anytime.
        </b>
      </div>

      <Row>
        <Col md={6}>
          <div id="checkout">
            <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          </div>
        </Col>
        <Col md={6}>
          <div className="chat-container-checkout">
            <div className="th-prominent-chat dqt-chat">
              <div className="th-prominent-chat-window">
                <div className="chat-header-main">
                  <div className="expert-profile expert-profile--blue">
                    <div className="avatar">
                      <div className="avatar-wrapper">
                        <img
                          src={require("../assets/images/helprpfp.png")}
                          alt="img"
                        />
                      </div>
                    </div>
                    <div className="details">
                      <p className="name">Lauren </p>
                      <p className="title">AI Tech Expert</p>
                    </div>
                  </div>
                </div>
                <div data-testid="dialog-checkout" className="dialog-checkout body">
                  <div className="content">
                    <div className="conversation">
                      <div className="expert message th-chat-message">
                        <img
                          className="right-message-img"
                          src={require("../assets/images/helprpfp.png")}
                          alt="img"
                        />
                        <div className="content">
                          <span className="name">Lauren, AI Tech Expert</span>
                          <div className="checkout-chat-message">
                            Your AI tech expert is ready to help at no cost today!
                            Just fill out the form to claim your 7 day free trial
                            and get your issue resolved fast and ask unlimited
                            questions!!
                          </div>

                          <div className="checkout-chat-message-900">
                            Your expert is ready to help, just fill out the form to
                            claim your 7 day free trial and get your issue resolved
                            fast! Get Unlimited 24/7 Help From Your On Demand AI
                            Tech Expert. Never Deal With Technology Alone Again!
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="checkout-bullet-points-container">
                    <div className="checkout-pricing-headline-900">
                      <b>
                        Unlimited questions, completely free for 7 days, then just
                        $20/month. Cancel anytime.
                      </b>
                    </div>
                    <div className="checkout-bullet-points">
                      <div className="checkout-bullet-point">
                        <p>
                          Never deal with technology alone again! Your AI tech
                          expert is always online and ready to assist!
                        </p>
                      </div>
                      <div className="checkout-bullet-point">
                        <p>
                          Ask unlimited questions about any topic 24/7, get an
                          instant response from your expert!
                        </p>
                      </div>
                      <div className="checkout-bullet-point">
                        <p>
                          Instant help with any topic, from internet issues, to
                          recipes, math problems, balancing budgets, anything you
                          can think of!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
